import React from 'react';
import creditItems from './creditItems.json';

function CreditsPage() {
  return (
    <div className="my-5">
      <h1>Credits</h1>
      <p>
        This site benefits from the following open source projects.
        Thank you to all of the contributors.
      </p>
      {creditItems.map((creditItem) => (
        <div className="my-3">
          <h2 className="fs-4">{creditItem.name}</h2>
          {creditItem.description && <p className="mb-1">{creditItem.description}</p>}
          <p className="mb-1">
            &copy;
            {' '}
            {creditItem.copyright}
          </p>
          <p className="mb-1">
            <a href={creditItem.licenseURL} rel="external nofollow noreferrer" target="_blank">license</a>
            {' '}
            <a href={creditItem.homepageURL} rel="external nofollow noreferrer" target="_blank">homepage</a>
          </p>
        </div>
      ))}
    </div>
  );
}

export default CreditsPage;

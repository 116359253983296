import React from 'react';
import { Button } from 'react-bootstrap';

function Footer() {
  return (
    <div className="mt-5 d-flex justify-content-center flex-wrap no-print">
      <Button variant="link" href="/info/terms">Terms</Button>
      <Button variant="link" href="/info/privacy">Privacy</Button>
      <Button variant="link" href="/info/cookies">Cookies</Button>
      <Button variant="link" href="/info/credits">Credits</Button>
      <Button variant="link" href="/info/contact">Contact</Button>
    </div>
  );
}

export default Footer;

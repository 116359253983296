import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faBullhorn,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faEllipsisV,
  faInbox,
  faInfoCircle,
  faLightbulb,
  faPlus,
  faPrint,
  faSave,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faSquare,
  faCheckSquare,
  faPlus,
  faTrash,
  faUndo,
  faPrint,
  faSave,
  faChevronRight,
  faChevronLeft,
  faEllipsisV,
  faClipboard,
  faInbox,
  faBullhorn,
  faInfoCircle,
  faLightbulb,
);

import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ContactPage() {
  return (
    <Row>
      <Col md={3} />
      <Col md={6}>
        <h1 className="mt-5 fs-3">Feedback</h1>
        <p>
          If you have feedback about the site, please complete our feedback form
          to submit your feedback to our development team.
        </p>
        <Button className="w-100" data-az-l="080147ac-8ea0-4db7-a866-ba7feb48a8d0">
          <FontAwesomeIcon icon={['fas', 'bullhorn']} fixedWidth />
          {' '}
          Feedback
        </Button>
        <h1 className="mt-5 fs-3">Email</h1>
        <p>
          For any other inquiry, please contact us via email:
          {' '}
          <a href="mailto:contact@trichecklist.com">contact@trichecklist.com</a>
          .
        </p>
        <a href="mailto:contact@trichecklist.com">
          <Button className="w-100">
            <FontAwesomeIcon icon={['fas', 'inbox']} fixedWidth />
            {' '}
            Email
          </Button>
        </a>
      </Col>
      <Col md={3} />
    </Row>
  );
}

export default ContactPage;

import React from 'react';
import { Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ReactComponent as Logo } from '../img/logo.svg';

function Header() {
  return (
    <Navbar bg="primary" className="justify-content-center">
      <LinkContainer to="/">
        <Navbar.Brand>
          <Logo height={28} className="me-2 pb-1" alt="Triathlon Checklist" />
          <h1 className="d-inline-block fs-5 fw-normal">Triathlon Checklist</h1>
        </Navbar.Brand>
      </LinkContainer>
    </Navbar>
  );
}

export default Header;

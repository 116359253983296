import React, { lazy, Suspense } from 'react';
import './App.sass';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Page from './components/Page';
import './components/IconLibrary';
import LandingPage from './pages/Landing';
import CreditsPage from './pages/general/Credits';
import ContactPage from './pages/general/Contact';
import Info from './pages/general/Info';
import NotFoundPage from './pages/general/NotFound';
import { LoadingPage } from './pages/general/Loading';

const ReactLazyPreload = (factory) => {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
};

const BuildPage = ReactLazyPreload(() => import('./pages/Build'));
const ListPage = ReactLazyPreload(() => import('./pages/List'));

function HeaderTags() {
  const description = ''
    + 'Wondering what to pack for a triathlon? '
    + 'Build a custom triathlon checklist for your next sprint, olympic, half ironman, or ironman race.';

  return (
    <Helmet>
      <title>Triathlon Checklist</title>

      <meta name="description" content={description} />

      <link rel="canonical" href="https://trichecklist.com/" />

      <meta property="og:title" content="Triathlon Checklist" />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="Triathlon Checklist" />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}

class App extends React.Component {
  componentDidMount() {
    BuildPage.preload();
    ListPage.preload();
  }

  render() {
    return (
      <Router>
        <HeaderTags />
        <Page>
          <Suspense fallback={<LoadingPage />}>
            <Switch>
              <Route exact path="/">
                <LandingPage />
              </Route>
              <Route path="/build">
                <BuildPage />
              </Route>
              <Route path="/list/:id_slug">
                <ListPage />
              </Route>
              <Route path="/list">
                <ListPage />
              </Route>
              <Route path="/info/credits">
                <CreditsPage />
              </Route>
              <Route path="/info/contact">
                <ContactPage />
              </Route>
              <Route path="/info/:pageKey">
                <Info />
              </Route>
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </Suspense>
        </Page>
      </Router>
    );
  }
}

export default App;

import React from 'react';

function NotFoundPage() {
  return (
    <div className="my-5 text-center">
      <h1 className="mb-5">404: Not Found 🕵️😕</h1>

      <p>
        We looked everywhere but couldn&apos;t find that page.
        The link you clicked is broken or the page was removed.
      </p>

      <p>
        Please visit the
        {' '}
        <a href="/">home page</a>
        {' '}
        or
        {' '}
        <a href="/info/contact">contact us</a>
        {' '}
        about this issue.
      </p>
    </div>
  );
}

export default NotFoundPage;

import React from 'react';
import { hydrate, render } from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './customBootstrap.sass';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://772507bdc3464c35899e0f4f1f28764f@o1119143.ingest.sentry.io/6153432',
  });
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

if (process.env.NODE_ENV === 'production') {
  reportWebVitals();
}

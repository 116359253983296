import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ReactComponent as LineArt } from '../img/line_art.svg';
import { ReactComponent as BuildIcon } from '../img/icons/build.svg';
import { ReactComponent as CustomizeIcon } from '../img/icons/customize.svg';
import { ReactComponent as DiscoverIcon } from '../img/icons/discover.svg';

function IconCard(props) {
  const { icon, title, text } = props;
  const Icon = icon;
  return (
    <div className="d-flex align-items-start">
      <Icon className="px-2" style={{ maxWidth: 96 }} />
      <div>
        <h3 className="card-title fs-5">{title}</h3>
        <p className="card-text">{text}</p>
      </div>
    </div>
  );
}

function LandingPage() {
  return (
    <>
      <Row className="mt-3">
        <Col md={5}>
          <div className="px-4 py-5">
            <h2 className="">Packing for a triathlon?</h2>
            <p>
              First race or pro, build a custom list to make sure you
              have everything you need to succeed at your next race.
            </p>
            <LinkContainer to="/build" className="w-100 mb-4">
              <Button size="lg">Build My Checklist</Button>
            </LinkContainer>
          </div>
          <div className="d-none d-md-block" style={{ height: 10 }} />
        </Col>
        <Col md={7} className="order-md-first d-flex align-items-end justify-content-center px-3">
          <LineArt style={{ overflow: 'visible', maxWidth: '100%', maxHeight: 210, display: 'block' }} />
        </Col>
      </Row>
      <Row className="g-5 mx-1 mt-5">
        <Col className="px-2 px-md-3" md={4}>
          <IconCard
            icon={BuildIcon}
            title="Build"
            text="Answer a few quick questions about your next race to create a checklist"
          />
        </Col>
        <Col className="px-2 px-md-3" md={4}>
          <IconCard
            icon={CustomizeIcon}
            title="Customize"
            text="Edit your list to your liking, and save or share your personalized checklist"
          />
        </Col>
        <Col className="px-2 px-md-3" md={4}>
          <IconCard
            icon={DiscoverIcon}
            title="Discover"
            text="Find new solutions and tips right in your checklist"
          />
        </Col>
      </Row>
    </>
  );
}

export default LandingPage;

import React from 'react';
import { useParams } from 'react-router-dom';
import NotFoundPage from './NotFound';
import cookiesContent from '!html-loader!./cookies.html';
import privacyContent from '!html-loader!./privacy.html';
import termsContent from '!html-loader!./terms.html';

function Info() {
  const { pageKey } = useParams();
  const pageContentMap = {
    cookies: cookiesContent,
    privacy: privacyContent,
    terms: termsContent,
  };

  if (!(pageKey in pageContentMap)) {
    return <NotFoundPage />;
  }

  return (
    /* eslint-disable react/no-danger */
    <div className="my-5" dangerouslySetInnerHTML={{ __html: pageContentMap[pageKey] }} />
  );
}

export default Info;

function sendToGoogleAnalytics({ name, delta, value, id }) {
  window.gtag('event', name, {
    value: delta, // Use `delta` so the value can be summed.
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
  });
}

function reportWebVitals() {
  import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(sendToGoogleAnalytics);
    getFID(sendToGoogleAnalytics);
    getFCP(sendToGoogleAnalytics);
    getLCP(sendToGoogleAnalytics);
    getTTFB(sendToGoogleAnalytics);
  });
}

export default reportWebVitals;

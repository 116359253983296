import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

class DelayedRender extends Component {
  constructor() {
    super();

    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { delay } = this.props;

    this.delayTimer = setTimeout(() => { this.setState({ show: true }); }, delay);
  }

  componentWillUnmount() {
    clearTimeout(this.delayTimer);
  }

  render() {
    const { show } = this.state;
    const { children } = this.props;

    if (!show) return null;

    return children;
  }
}

export function Loading() {
  return (
    // Slight delay before showing the loading spinner so that it doesn't flash when the page loads quickly
    <DelayedRender delay={250}>
      <div className="my-5 text-center">
        <div>Loading...</div>
        <div className="mt-2"><Spinner animation="border" /></div>
      </div>
    </DelayedRender>
  );
}

export function LoadingPage() {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
      <Loading />
    </div>
  );
}
